.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




/* Toast Container */
.Toastify__toast-container {
  margin-top: 20px !important;
  width: 350px !important;
}

/* Toast Box */
.Toastify__toast {
  min-height: 60px !important;
  border-radius: 8px !important;
  margin-bottom: 16px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  padding: 12px 16px !important;
  font-family: 'Arial', sans-serif !important;
}

/* Toast Text */
.Toastify__toast-body {
  margin: 0 !important;
  font-size: 16px !important;
  color: #333 !important;
  line-height: 1.5 !important;
  padding: 0 10px !important;
}

/* Success Progress Bar */
.Toastify__progress-bar--success {
  background: linear-gradient(90deg, #10B981, #34D399) !important;
  height: 4px !important;
  border-radius: 0 0 4px 4px !important;
}

/* Error Progress Bar */
.Toastify__progress-bar--error {
  background: linear-gradient(90deg, #EF4444, #F87171) !important;
  height: 4px !important;
  border-radius: 0 0 4px 4px !important;
}

/* Close Button */
.Toastify__close-button {
  color: #888 !important;
  opacity: 0.8 !important;
  font-size: 14px !important;
}
.Toastify__close-button:hover {
  opacity: 1 !important;
  color: #333 !important;
}

/* Toast Icon */
.Toastify__toast-icon {
  margin-right: 12px !important;
  font-size: 20px !important;
  color: #10B981 !important; /* Customize for success, change per type if needed */
}

/* Example for Different Toast Types */
.Toastify__toast--success {
  background-color: #ECFDF5 !important;
  border-left: 4px solid #10B981 !important;
}

.Toastify__toast--error {
  background-color: #FEF2F2 !important;
  border-left: 4px solid #EF4444 !important;
}

.Toastify__toast--warning {
  background-color: #FFFBEB !important;
  border-left: 4px solid #FBBF24 !important;
}

.Toastify__toast--info {
  background-color: #EFF6FF !important;
  border-left: 4px solid #3B82F6 !important;
}
body{
  background-color: #19202F;
}

